import { GithubFilled, HeartFilled } from "@ant-design/icons"

export const AbiFooter = () => {
  return (
    <div className='footer-items'>
      <p>
        Brought to you by{" "}
        <a href='https://t.me/ChewySwapCommunity/' target='_blank' rel='noreferrer'>
          ChewySwap
        </a>{" "}
        /{" "}
        <a href='https://t.me/+_odA4ys894piMGJh' target='_blank' rel='noreferrer'>
          PooShib
        </a>
      </p>
    </div>
  )
}
